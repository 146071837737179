import React, { useState } from "react";
import "video.js/dist/video-js.css";
import placeholder from "../../static/tools/11.png";
import { Link } from "@reach/router";
import { slugify } from "../../util";
import { useMyClasses } from "../../hooks/useMyClasses";
import Spinner from "../Common/Spinner";
import { useLocalStorage } from "react-use";
import Vid from "./content/Vid";
import { PageTitle } from "../Common/PageTitle";
import { Transition } from "@headlessui/react";
import { DocumentModal } from "./DocumentModal";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import { isEmpty } from "lodash";

const VIDEO_URL =
  "https://waxcarvers-videos.s3.eu-west-1.amazonaws.com/account-walkthrough/account-walkthrough.m3u8";

const ProductCard = ({ product }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="aspect-w-6 aspect-h-5">
        <img
          className="object-cover rounded-md"
          src={product.images.length > 0 ? product.images[0] : placeholder}
          alt={product.name}
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="self-center text-sm leading-5 font-medium text-indigo-600">
            {startCase(camelCase(product.metadata.type))}
          </p>
          <div className="block self-center ">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900 text-center">
              {product.name}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500 text-center">
              {product.description}
            </p>
          </div>
        </div>
        <Link
          className="self-center my-3 text-lg leading-7 text-blue-700"
          to={`/app/my-profile/${slugify(product.name)}/lesson/`}
        >
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              See item
            </button>
          </span>
        </Link>
      </div>
    </div>
  );
};

const BundleCard = ({ bundle, name }) => {
  return (
    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="aspect-w-6 aspect-h-5">
        <img
          className="object-cover rounded-md"
          src={bundle?.image ? bundle.image : placeholder}
          alt={name}
        />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="self-center text-sm leading-5 font-medium text-indigo-600">
            {startCase(camelCase(bundle?.type))}
          </p>
          <div className="block self-center ">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900 text-center">
              {bundle.title}
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500 text-center">
              {bundle?.description}
            </p>
          </div>
        </div>
        <Link
          className="self-center my-3 text-lg leading-7 text-blue-700"
          to={`/app/my-profile/${slugify(name)}/lesson/`}
        >
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center px-6 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
            >
              See item
            </button>
          </span>
        </Link>
      </div>
    </div>
  );
};

const GetMoreClasses = () => (
  <div className="relative pt-6 pb-12 lg:pb-20">
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
      <div className="text-center">
        <h2 className="text-md tracking-tight leading-10 font-bold text-gray-800 sm:text-xl sm:leading-none md:text-2xl">
          Interested in other classes?
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          <button className="bg-blue-700 rounded-md shadow py-3 px-6">
            <Link to={"/classes"}>Take a look at what is available</Link>
          </button>
        </p>
      </div>
    </div>
  </div>
);
const NothingHere = () => (
  <div className="mt-40 align-middle flex flex-col justify-center text-center">
    <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
      <div className="text-center">
        <h2 className="text-md tracking-tight leading-10 text-gray-800 sm:text-md sm:leading-none md:text-md">
          Nothing here yet. Take a look at available classes or contact
          info@waxcarvers.com for more information.
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-white sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          <button className="bg-blue-700 rounded-md shadow py-3 px-6">
            <Link to={"/classes"}>Available classes</Link>
          </button>
        </p>
      </div>
    </div>
  </div>
);

const WalkThrough = ({ xButtonAction }) => (
  <div className="bg-gray-100 shadow sm:rounded-lg mb-12">
    <div className="px-4 py-5 sm:p-6 text-center">
      <div className="flex relative">
        <h2 className="absolute inset-x-0 top-0 text-2xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
          Account walkthrough
        </h2>
        <div className="absolute top-0 right-0">
          <button
            type="button"
            onClick={xButtonAction}
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="mt-12">
        <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
          See below video on how to use your account.
        </p>
      </div>
      <div className="mt-5">
        <div className="max-w-screen-md flex mx-auto justify-items-center justify-center object-center">
          <Vid
            className="self-center"
            videoJsOptions={{
              description: "Charms",
              name: "Account Walktrhough",
              urls: [VIDEO_URL],
              video: {
                autoplay: false,
                controls: true,
                src: VIDEO_URL,
                type: "application/x-mpegURL",
              },
            }}
          />
        </div>
      </div>
    </div>
  </div>
);

const MyClasses = () => {
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const { spinning, items, bundles } = useMyClasses();
  const [seenVideo, setSeenVideo] = useLocalStorage(
    "waxcarvers-walkthrough-video-seen",
    false,
  );
  const reducedItems = Object.values(
    items.reduce((acc, it) => {
      if (it.metadata.type === "upgrade") return acc;
      if (!acc[slugify(it.name)]) {
        acc[slugify(it.name)] = it;
      }
      return acc;
    }, {}),
  );
  reducedItems.sort(
    (a, b) =>
      new Date(a.metadata.end_date).getTime() -
      new Date(b.metadata.end_date).getTime(),
  );

  return (
    <div>
      <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3">&nbsp;</div>
        </div>
        <div className="relative max-w-7xl mx-auto">
          {seenVideo ? null : (
            <WalkThrough xButtonAction={() => setSeenVideo(true)} />
          )}
          <PageTitle
            title="My Purchases"
            subtitle="You have bought the following items."
          />
          <div
            className="self-center my-3 text-lg leading-7 text-blue-700 mt-5 sm:mt-6 sm:grid sm:grid-cols-1 sm:gap-3 sm:grid-flow-row-dense"
            onClick={() => {
              setShowDocumentModal(true);
            }}
          >
            <span className="inline-flex rounded-md shadow-sm">
              <button
                type="button"
                className="inline-flex items-center px-6 py-3 border border-gray-300 text-base leading-6 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
              >
                Wax to Metal conversion
              </button>
            </span>
          </div>
          {spinning ? (
            <Spinner text="Fetching your items" />
          ) : reducedItems.length === 0 && isEmpty(bundles) ? (
            <NothingHere />
          ) : (
            <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
              {Object.entries(bundles).map(([bundleName, metadata]) => (
                <BundleCard
                  bundle={metadata}
                  name={bundleName}
                  key={bundleName}
                />
              ))}
              {reducedItems.map((it) => (
                <ProductCard product={it} key={it.id} />
              ))}
            </div>
          )}
        </div>
      </div>
      {reducedItems.length > 0 ? <GetMoreClasses /> : null}
      <Transition
        show={showDocumentModal}
        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enterTo="opacity-100 translate-y-0 sm:scale-100"
        enter="ease-out duration-300"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
        <DocumentModal
          close={() => setShowDocumentModal(false)}
          isOpen={showDocumentModal}
        />
      </Transition>
    </div>
  );
};

export default MyClasses;
